import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

const VideoPlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
`;

const VideoPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const ResponsivePlayer = (props) => {
  return (
    <VideoPlayerContainer>
      <VideoPlayer url={props.url} width="100%" height="100%" />
    </VideoPlayerContainer>
  );
};

export default ResponsivePlayer;
