import React from "react";
import { useTranslation } from "react-i18next";

import GridSection from '../../../../ui/GridSection'
import Text from '../../../../ui/Text';
import { Section, CenteredGridItem, TextContainer, Image } from "./styles.js";
import ImgAuthor from "../../../../assets/sabina.webp";
import ImgAuthorFallback from "../../../../assets/sabina.jpg";
import { colors } from "../../../../ui/theme";

const AboutSection = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <GridSection>
        <CenteredGridItem m={2} t={8} d={6}>
          <TextContainer>
            <Text color={colors.white} variant="section" textAlign="center">
              {t("about.title")}
            </Text>
            <Text
              color={colors.white}
              variant="bodyLarge"
              textAlign="justify"
              px={50}
            >
              {t("about.description")}
            </Text>
          </TextContainer>
        </CenteredGridItem>
        <CenteredGridItem m={2} t={8} d={6}>
          <Image src={ImgAuthor} fallback={ImgAuthorFallback}/>
        </CenteredGridItem>
      </GridSection>
    </Section>
  );
};

export default AboutSection;
