import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as WhiteLogo } from "../../assets/logoWhite.svg";
import { colors } from "../theme";

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  background: ${(props) =>
    props.isScrolled || props.showMenu ? colors.platinum : "transparent"};
  z-index: ${(props) => (props.isScrolled ? 2 : 0)};
  transition: all 0.3s ease;
  box-shadow: ${(props) =>
    props.isScrolled
      ? "0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)"
      : "none"};
`;

const SiteLogo = styled(Logo)`
  height: 80px;
`;

const WhiteSiteLogo = styled(WhiteLogo)`
  height: 80px;
`;

const NavMenu = styled.div`
  display: none;
  text-align: center;
  @media (max-width: ${(props) => props.size}) {
    display: block;
    margin: 25px 0;
  }
`;

const NavLargeMenu = styled.div`
  display: block;
  text-align: center;
  @media (max-width: ${(props) => props.size}) {
    display: none;
  }
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  padding: 30px 0;
  margin: 0;
  align-items: center;
  transition: all 0.3s ease;
  @media (max-width: ${(props) => props.size}) {
    display: block;
  }
`;

const ListItem = styled.li`
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.3s ease;
  text-decoration: none;
  border-bottom: transparent 1px solid;
  &:hover {
    border-bottom: 1px solid;
    border-bottom-color: ${(props) =>
      props.isScrolled ? colors.black : colors.white};
  }
  @media (max-width: ${(props) => props.size}) {
    display: block;
    margin: 20px 0;
  }
`;

const MenuButton = styled.button`
  background: transparent;
  border: 0px solid transparent;
`;

const Anchor = styled(Link)`
  text-decoration: none;
  color: ${(props) => (props.isScrolled ? colors.black : colors.white)};
  font-family: Museo;
`;

export {
  Wrapper,
  Nav,
  NavMenu,
  NavLargeMenu,
  NavList,
  ListItem,
  Anchor,
  SiteLogo,
  WhiteSiteLogo,
  MenuButton,
};
