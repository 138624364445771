import React from "react";
import { useTranslation } from "react-i18next";

import { Section, CenteredTextContainer } from "./styles";
import { GridSection, GridItem, Text, Button } from "../../../../ui";
import { colors } from "../../../../ui/theme";

const WelcomeSection = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <GridSection>
        <GridItem m={2} t={8} d={12}>
          <CenteredTextContainer>
            <Text variant="display" color={colors.white}>
              {t("welcome.title")}
            </Text>
            <Button width="200px" onClick={onClick}>
              {t("results.showMe")}
            </Button>
          </CenteredTextContainer>
        </GridItem>
      </GridSection>
    </Section>
  );
};

export default WelcomeSection;
