import React, { useState, useEffect } from "react";
import { MdMenu, MdClose } from "react-icons/md";
import { colors } from "../theme";

import {
  Wrapper,
  Nav,
  NavMenu,
  NavLargeMenu,
  NavList,
  ListItem,
  Anchor,
  SiteLogo,
  WhiteSiteLogo,
  MenuButton,
} from "./styles";

const MAX_WIDTH = 840;

const getWidth = () => {
  const { innerWidth: width } = window;
  return width;
};

const NavBar = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = getWidth();
      if (width > MAX_WIDTH) {
        setShowMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const menu = (
    <NavList size={props.size}>
      <ListItem isScrolled={props.isScrolled || showMenu} size={props.size}>
        <Anchor isScrolled={props.isScrolled || showMenu} smooth to="/#about">
          Sobre
        </Anchor>
      </ListItem>
      <ListItem isScrolled={props.isScrolled || showMenu} size={props.size}>
        <Anchor isScrolled={props.isScrolled || showMenu} smooth to="/#book">
          Livro
        </Anchor>
      </ListItem>
    </NavList>
  );

  return (
    <Wrapper>
      <Nav isScrolled={props.isScrolled} showMenu={showMenu}>
        <Anchor smooth to="/#intro">
          {props.isScrolled || showMenu ? <SiteLogo /> : <WhiteSiteLogo />}
        </Anchor>
        <NavLargeMenu size={props.size}>{menu}</NavLargeMenu>
        <NavMenu size={props.size}>
          <MenuButton onClick={handleMenuClick}>
            {!showMenu ? (
              <MdMenu
                size={28}
                color={props.isScrolled ? colors.black : colors.white}
              />
            ) : null}
            {showMenu ? <MdClose size={28} /> : null}
          </MenuButton>
          {showMenu ? <div>{menu}</div> : null}
        </NavMenu>
      </Nav>
    </Wrapper>
  );
};

export default NavBar;
