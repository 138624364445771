import React from "react";
import { useTranslation } from "react-i18next";

import { Section, InverseLine, TextContainer } from "../styles";
import { PageBlock, GridSection, GridItem, Text, Modal } from "../../../ui";
import { colors } from "../../../ui/theme";

const PyramidSection = ({ inverseTriangleArray }) => {
  const { t } = useTranslation();

  const renderReverseTriangleNumber = () => {
    const inverseTriangle = inverseTriangleArray?.map((line) => {
      return (
        <InverseLine color={colors.white}>
          {line.map((element) => {
            return (
              <Text variant="inverseTriangle" color={colors.white}>
                {element}
              </Text>
            );
          })}
        </InverseLine>
      );
    });

    return inverseTriangle;
  };

  return (
    <PageBlock
      minHeight="100vh"
      background={colors.gunMetal}
      flexDirection="column"
    >
      <span>
        <Text variant="sectionSecondary" color={colors.white}>
          {t("results.name.inverseTriangle")}
        </Text>
        <Modal>
          <div>
            <Text variant="sectionSecondary" color={colors.white}>
              {t("results.name.inverseTriangle")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.inverseTriangle")}
            </Text>
          </div>
        </Modal>
      </span>
      <Section>
        <GridSection>
          <GridItem m={2} t={8} d={12}>
            <TextContainer>{renderReverseTriangleNumber()}</TextContainer>
          </GridItem>
        </GridSection>
      </Section>
    </PageBlock>
  );
};

export default PyramidSection;
