import { letterMap, vowelArray } from "./constants";

const isVowel = (character) => {
  return vowelArray.includes(character);
};

const calculateSums = (name) => {
  let totalSum = 0;
  let vowelSum = 0;
  let consonantSum = 0;

  [...name].forEach((c) => {
    totalSum += letterMap[c];
    if (isVowel(c)) {
      vowelSum += letterMap[c];
    } else {
      consonantSum += letterMap[c];
    }
  });

  const totalSumArray = decomposeSum(totalSum);
  const vowelSumArray = decomposeSum(vowelSum);
  const consonantSumArray = decomposeSum(consonantSum);
  return { totalSumArray, vowelSumArray, consonantSumArray };
};

const returnFirstVowel = (name) => {
  let firstIndex = 0;
  for (firstIndex; firstIndex < name.length; firstIndex++) {
    if (vowelArray.includes(name.charAt(firstIndex))) {
      break;
    }
  }
  return name[firstIndex];
};

const crazySum = (number) => {
  const firstNumber = (number / 10) >> 0;
  const secondNumber = number % 10 >> 0;
  return firstNumber + secondNumber;
};

const decomposeSum = (sum) => {
  let sumArray = [];
  sumArray.push(sum);

  let auxSum = sum;

  while (auxSum >= 10) {
    let crazy = crazySum(auxSum);
    sumArray.push(crazy);
    auxSum = crazy;
  }
  return sumArray;
};

const inverseTriangle = (name) => {
  const splitName = name.substring(0, 9);

  let arrayLength = 8;
  let nameArray = [];
  let auxArray = [];
  const history = [];

  for (let i = 0; i < splitName.length; i++) {
    nameArray.push(letterMap[splitName.charAt(i)]);
  }

  while (arrayLength > 0) {
    for (let i = 0; i < arrayLength; i++) {
      auxArray[i] = nameArray[i] + nameArray[i + 1];
      if (auxArray[i] > 9) {
        const sumArray = decomposeSum(auxArray[i]);
        auxArray[i] = sumArray[sumArray.length - 1];
      }
    }
    history.push(auxArray);
    arrayLength--;
    nameArray = auxArray;
    auxArray = [];
  }
  return history;
};

const getNameLength = (name) => {
  const nameLength = [];
  if (name.length > 9) {
    const nameLengthArray = decomposeSum(name.length);
    return nameLengthArray;
  }
  return nameLength.push(name.length);
};

const giveNameCalculations = (name) => {
  const normalizedName = name.toLowerCase().trim().replace(/\s/g, "");
  const firstLetter = normalizedName[0];
  const nameLength = getNameLength(normalizedName);
  const firstVowel = returnFirstVowel(normalizedName);
  const nameSum = calculateSums(normalizedName);
  const inverseTriangleNumber = inverseTriangle(normalizedName);
  return {
    nameSum,
    nameLength,
    firstVowel,
    firstLetter,
    inverseTriangleNumber,
  };
};

export { giveNameCalculations };
