export const DESKTOP_MIN_WIDTH = 840;
export const TABLET_MIN_WIDTH = 600;
export const MOBILE_MIN_WIDTH = 0;

const measureMediaQuery = (measure) => {
  return `@media (min-width: ${measure}px)`;
};

const measures = {
  desktop: measureMediaQuery(DESKTOP_MIN_WIDTH),
  tablet: measureMediaQuery(TABLET_MIN_WIDTH),
  mobile: measureMediaQuery(MOBILE_MIN_WIDTH),
};

export default measures;
