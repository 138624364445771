import React from "react";
import styled from "styled-components";

import measures from "../theme/measures";

const StyledGridItem = styled.div`
  box-sizing: border-box;
  grid-column-end: span ${(props) => props.m};
  margin: 16px 0;

  ${measures.tablet} {
    grid-column-end: span ${(props) => props.t};
  }

  ${measures.desktop} {
    grid-column-end: span ${(props) => props.d};
    margin: 0;
  }
`;

const GridItem = (props) => {
  return (
    <StyledGridItem
      className={props.className}
      bg={props.bg}
      m={props.m}
      t={props.t}
      d={props.d}
    >
      {props.children}
    </StyledGridItem>
  );
};

export default GridItem;
