const colors = {
  white: "#ffffff",
  black: "#000000",
  gunMetal: "#293241",
  purpleNavy: "#3D5A80",
  lightCyan: "#E0FBFC",
  paleCerulean: "#98c1d9",
  ronchi: "#DFAF2B",
  platinum: "#D1E7E1",
  fiord: "#404D68",
};

export default colors;
