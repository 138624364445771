import { css } from "styled-components/macro";
import measures from "./measures";

const typography = {
  display: {
    component: "h1",
    style: css`
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      ${measures.tablet} {
        font-size: 56px;
        line-height: 67px;
      }
    `,
  },
  section: {
    component: "h2",
    style: css`
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -1px;
      ${measures.tablet} {
        font-size: 40px;
        line-height: 48px;
      }
    `,
  },
  sectionSecondary: {
    component: "h3",
    style: css`
      font-size: 32px;
      line-height: 38px;
    `,
  },
  subtitleLarge: {
    component: "h4",
    style: css`
      font-size: 24px;
      line-height: 29px;
    `,
  },
  subtitleSmall: {
    component: "h5",
    style: css`
      font-size: 17px;
      line-height: 20px;
    `,
  },

  bodyLargest: {
    component: "p",
    style: css`
      font-size: 22px;
      line-height: 26px;
    `,
  },
  bodyLarge: {
    component: "p",
    style: css`
      font-size: 17px;
      line-height: 24px;
    `,
  },
  bodySmall: {
    component: "p",
    style: css`
      font-size: 15px;
      line-height: 22px;
    `,
  },
  bodySmallest: {
    component: "p",
    style: css`
      font-size: 13px;
      line-height: 20px;
    `,
  },
  bodyResponsive: {
    component: "p",
    style: css`
      font-size: 13px;
      line-height: 20px;
      ${measures.tablet} {
        font-size: 17px;
        line-height: 24px;
      }
    `,
  },
  caption: {
    component: "p",
    style: css`
      font-size: 15px;
      line-height: 20px;
    `,
  },
  label: {
    component: "label",
    style: css`
      font-size: 13px;
      line-height: 16px;
    `,
  },
  linkLarge: {
    component: "p",
    style: css`
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    `,
  },
  tag: {
    component: "p",
    style: css`
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
    `,
  },

  inverseTriangle: {
    component: "span",
    style: css`
      font-size: 19px;
      line-height: 22px;
      padding: 0 15px;

      ${measures.tablet} {
        font-size: 24px;
        line-height: 26px;
        padding: 0 30px;
      }

      ${measures.desktop} {
        font-size: 24px;
        line-height: 26px;
        padding: 0 40px;
      }
    `,
  },
};

export default typography;
