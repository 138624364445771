import { birthdayMap } from "../helpers/constants";

const getAllDigits = (day, month, year) => {
  let allDigits = [];
  allDigits = allDigits.concat(getDigits(day));
  allDigits = allDigits.concat(getDigits(month));
  allDigits = allDigits.concat(getDigits(year));
  return allDigits;
};

const getDigits = (number) => {
  const digits = [];
  while (number > 0) {
    digits.push(number % 10);
    number = parseInt(number / 10);
  }
  return digits;
};

const getReducedDate = (digits) => {
  let sum = digits.reduce((total, element) => {
    return total + element;
  });

  if (sum >= 10) {
    sum = ((sum / 10) >> 0) + (sum % 10 >> 0);
  }
  return sum;
};

const getDayType = (day) => {
  const digits = getDigits(day);
  const reducedNumber = getReducedDate(digits);

  return birthdayMap[reducedNumber];
};

const getFirstChallenge = (day, month) => {
  let allDigits = [];
  allDigits = allDigits.concat(getDigits(day));
  allDigits = allDigits.concat(getDigits(month));
  return getReducedDate(allDigits);
};

const getPersonalMonth = (personalYear) => {
  let personalMonth = new Date().getMonth() + 1 + personalYear;

  if (personalMonth >= 10) {
    personalMonth = ((personalMonth / 10) >> 0) + (personalMonth % 10 >> 0);
  }

  return personalMonth;
};

const getPinnacles = (date, birthdayArray) => {
  const firstPinnacleArray = getDigits(date.getDate() + 1).concat(
    getDigits(date.getMonth() + 1)
  );
  const secondPinnacleArray = getDigits(date.getDate() + 1).concat(
    getDigits(date.getFullYear())
  );
  const fourthPinnacleArray = getDigits(date.getMonth() + 1).concat(
    getDigits(date.getFullYear())
  );

  const firstPinnacle = getReducedDate(firstPinnacleArray);
  const secondPinnacle = getReducedDate(secondPinnacleArray);
  const fourthPinnacle = getReducedDate(fourthPinnacleArray);

  const thirdPinnacle = reduceNumber(firstPinnacle + secondPinnacle);

  const firstInterval = 36 - getReducedDate(birthdayArray);

  return {
    firstPinnacle,
    secondPinnacle,
    thirdPinnacle,
    fourthPinnacle,
    firstInterval,
  };
};

const reduceNumber = (number) => {
  if (number < 10) {
    return number;
  }
  return ((number / 10) >> 0) + (number % 10 >> 0);
};

const getChallenges = (date, birthdayArray) => {
  const dateArray = getDigits(date.getDate() + 1);
  const monthArray = getDigits(date.getMonth() + 1);
  const yearArray = getDigits(date.getFullYear());

  const dateSum = getReducedDate(dateArray);
  const monthSum = getReducedDate(monthArray);
  const yearSum = getReducedDate(yearArray);

  const firstChallenge = reduceNumber(Math.abs(dateSum - monthSum));
  const secondChallenge = reduceNumber(Math.abs(dateSum - yearSum));
  const thirdChallenge = reduceNumber(
    Math.abs(firstChallenge - secondChallenge)
  );
  const fourthChallenge = reduceNumber(Math.abs(monthSum - yearSum));

  const firstInterval = 36 - getReducedDate(birthdayArray);

  return {
    firstChallenge,
    secondChallenge,
    thirdChallenge,
    fourthChallenge,
    firstInterval,
  };
};

const calculateDateObject = (dateString) => {
  const dateObject = new Date(dateString);

  const birthdayArray = getAllDigits(
    dateObject.getDate() + 1,
    dateObject.getMonth() + 1,
    dateObject.getFullYear()
  );

  const reducedCurrentYearArray = getAllDigits(
    dateObject.getDate() + 1,
    dateObject.getMonth() + 1,
    new Date().getFullYear()
  );

  const reducedBirthday = getReducedDate(birthdayArray);
  const personalYear = getReducedDate(reducedCurrentYearArray);
  const dayType = getDayType(dateObject.getDate() + 1);
  const firstChallenge = getFirstChallenge(
    dateObject.getDate() + 1,
    dateObject.getMonth() + 1
  );
  const personalMonth = getPersonalMonth(personalYear);

  const pinnacles = getPinnacles(dateObject, birthdayArray);
  const challenges = getChallenges(dateObject, birthdayArray);

  return {
    reducedBirthday,
    dayType,
    firstChallenge,
    personalYear,
    personalMonth,
    pinnacles,
    challenges,
  };
};

export default calculateDateObject;
