import React from "react";
import styled from "styled-components";
import { measures } from "../../ui/theme";

const CustomBlock = styled.div`
  min-height: ${(props) => props.minHeight};
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: ${(props) => props.paddingTop};

  ${measures.tablet} {
    padding-top: 0px;
    padding: 0 32px;
  }

  ${measures.desktop} {
    padding-top: 0px;
    padding: 0 96px;
  }
`;

const PageBlock = ({
  children,
  background,
  minHeight,
  paddingTop,
  flexDirection,
  image,
  id,
}) => {
  return (
    <CustomBlock
      id={id}
      minHeight={minHeight}
      background={background}
      paddingTop={paddingTop}
      flexDirection={flexDirection}
      image={image}
    >
      {children}
    </CustomBlock>
  );
};

export default PageBlock;
