import React from "react";
import { useTranslation } from "react-i18next";

import { PageBlock, GridSection, Text, Modal } from "../../../ui";
import { colors } from "../../../ui/theme";
import { Section, CustomGridItem, TextContainer } from "../styles";

const PinnacleSection = ({ pinnacles, challenges }) => {
  const { t } = useTranslation();

  const getTextVariant = () => {
    let variant = "bodySmall";
    let titleVariant = "bodyLarge";
    const { innerWidth: width } = window;

    if (width >= 840) {
      titleVariant = "subtitleLarge";
      variant = "bodyLargest";
    } else if (width >= 600) {
      titleVariant = "subtitleSmall";
      variant = "bodyLarge";
    }

    return { variant, titleVariant };
  };

  const renderColumn = (
    title,
    { first, second, third, fourth },
    textAlign = "center"
  ) => {
    const { variant: textVariant, titleVariant } = getTextVariant();
    return (
      <TextContainer>
        <Text color={colors.white} variant={titleVariant} textAlign={textAlign}>
          {title}
        </Text>
        <Text color={colors.white} variant={textVariant} textAlign={textAlign}>
          {first}
        </Text>
        <Text color={colors.white} variant={textVariant} textAlign={textAlign}>
          {second}
        </Text>
        <Text color={colors.white} variant={textVariant} textAlign={textAlign}>
          {third}
        </Text>
        <Text color={colors.white} variant={textVariant} textAlign={textAlign}>
          {fourth}
        </Text>
      </TextContainer>
    );
  };

  const renderPinnacles = () => {
    const labels = {
      first: t("results.pinnacles.first"),
      second: t("results.pinnacles.second"),
      third: t("results.pinnacles.third"),
      fourth: t("results.pinnacles.fourth"),
    };
    const values = {
      first: pinnacles?.firstPinnacle,
      second: pinnacles?.secondPinnacle,
      third: pinnacles?.thirdPinnacle,
      fourth: pinnacles?.fourthPinnacle,
    };
    const duration = {
      first: [pinnacles?.firstInterval, t("results.intervals.years")].join(" "),
      second: t("results.intervals.nineYears"),
      third: t("results.intervals.nineYears"),
      fourth: t("results.intervals.endOfLife"),
    };

    return (
      <Section>
        <GridSection columns={3}>
          <CustomGridItem m={0.5} t={3} d={4}>
            {renderColumn("Pináculo", labels)}
          </CustomGridItem>
          <CustomGridItem m={0.6} t={2} d={4}>
            {renderColumn("Valor", values)}
          </CustomGridItem>
          <CustomGridItem m={0.6} t={2} d={4}>
            {renderColumn("Duração", duration)}
          </CustomGridItem>
        </GridSection>
      </Section>
    );
  };

  const renderChallenges = () => {
    const labels = {
      first: t("results.challenges.first"),
      second: t("results.challenges.second"),
      third: t("results.challenges.third"),
      fourth: t("results.challenges.fourth"),
    };
    const values = {
      first: challenges?.firstChallenge,
      second: challenges?.secondChallenge,
      third: challenges?.thirdChallenge,
      fourth: challenges?.fourthChallenge,
    };
    const duration = {
      first: [challenges?.firstInterval, t("results.intervals.years")].join(
        " "
      ),
      second: t("results.intervals.nineYears"),
      third: t("results.intervals.nineYears"),
      fourth: t("results.intervals.endOfLife"),
    };

    return (
      <Section>
        <GridSection columns={3}>
          <CustomGridItem m={1} t={3} d={4}>
            {renderColumn("Desafio", labels)}
          </CustomGridItem>
          <CustomGridItem m={1} t={2} d={4}>
            {renderColumn("Valor", values)}
          </CustomGridItem>
          <CustomGridItem m={1} t={2} d={4} alignItems="right">
            {renderColumn("Duração", duration)}
          </CustomGridItem>
        </GridSection>
      </Section>
    );
  };

  return (
    <PageBlock
      minHeight="50vh"
      background={colors.gunMetal}
      flexDirection="column"
    >
      <span>
        <Text variant="sectionSecondary" color={colors.white}>
          {t("results.name.pinnaclesChallenges")}
        </Text>
        <Modal>
          <div>
            <Text variant="sectionSecondary" color={colors.white}>
              {t("results.name.pinnaclesChallenges")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.pinnaclesChallenges")}
            </Text>
          </div>
        </Modal>
      </span>
      {renderPinnacles()}
      {renderChallenges()}
    </PageBlock>
  );
};

export default PinnacleSection;
