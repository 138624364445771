import styled from "styled-components";
import { GridSection, GridItem, Text } from "../../ui";
import { measures } from "../../ui/theme";

import { colors } from "../../ui/theme";

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredGridSection = styled(GridSection)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  background: ${(props) => props.bg};
  padding: 0 8px;

  ${measures.tablet} {
    padding: 0 16px;
  }

  ${measures.desktop} {
    padding: 0 30px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CenteredTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InverseLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
`;

const CustomText = styled(Text)`
  variant: bodySmall;

  ${measures.tablet} {
    variant: bodyLarge;
  }

  ${measures.desktop} {
    variant: sectionSecondary;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: 'flex-end';
  align-items: 'flex-end';
`

const CustomLink = styled.a`
  text-decoration: none;
  color: ${colors.white};
`

export {
  InverseLine,
  CustomGridItem,
  Section,
  TextContainer,
  CenteredTextContainer,
  CenteredGridSection,
  CenteredSection,
  CustomText,
  BottomContainer,
  CustomLink,
};
