import React from "react";
import { useTranslation } from "react-i18next";

import { GridSection, Text, Modal } from "../../../ui";
import GradientPageBlock from '../../../ui/GradientPageBlock';
import { Section, CustomGridItem, TextContainer } from "../styles";
import { colors } from "../../../ui/theme";

const modalArray = [1,2,3,4,5,6,7,8,9,11,22];

const BaseSection = ({ date, dateValues, name, nameValues, powerfulNumber }) => {
  const { t } = useTranslation();

  const formatDate = (dateString) => {
    const values = dateString.split("-");
    return values[2] + "/" + values[1] + "/" + values[0];
  };

  const getTextVariant = () => {
    let variant = "bodySmall";
    let titleVariant = "bodyLarge";
    const { innerWidth: width } = window;

    if (width >= 840) {
      titleVariant = "subtitleLarge";
      variant = "bodyLargest";
    } else if (width >= 600) {
      titleVariant = "subtitleSmall";
      variant = "bodyLarge";
    }

    return { variant, titleVariant };
  };

  const renderBasicResults = () => {
    return (
      <>
        <Section>
          <GridSection>
            <CustomGridItem m={2} t={4} d={6} alignItems="left">
              {renderNameColumn()}
            </CustomGridItem>
            <CustomGridItem m={2} t={4} d={6} alignItems="right">
              {renderDateColumn()}
            </CustomGridItem>
          </GridSection>
        </Section>
        <Section>
          <GridSection>
            <CustomGridItem m={12} t={12} d={12} alignItems="center">
              {renderPowerfulNumber()}
            </CustomGridItem>
          </GridSection>
        </Section>
        <Section>
          <GridSection paddingTop={32}>
            <CustomGridItem m={12} t={12} d={12} alignItems="center">
              <Text color={colors.white} variant="bodyLarge">
                {t("results.nameModal.title")}
              </Text>
              <Modal>
                <div>
                  <Text color={colors.white} variant="subtitleSmall">
                    {t("results.nameModal.title")}
                  </Text>
                  {renderNameModal()}
                </div>
              </Modal>
            </CustomGridItem>
          </GridSection>
        </Section>
        <Section>
          <GridSection marginBottom={32}>
            <CustomGridItem m={12} t={12} d={12} alignItems="center">
              <Text color={colors.white} variant="bodyLarge">
                {t("results.dateModal.title")}
              </Text>
              <Modal>
                <div>
                  <Text color={colors.white} variant="subtitleSmall">
                    {t("results.dateModal.title")}
                  </Text>
                  {renderDateModal()}
                </div>
              </Modal>
            </CustomGridItem>
          </GridSection>
        </Section>
      </>
    );
  };

  const renderNameColumn = () => {
    const { variant: textVariant, titleVariant } = getTextVariant();
    return (
      <TextContainer>
        <Text color={colors.white} variant={titleVariant}>
          {name}
        </Text>
        <Text color={colors.white} variant={textVariant}>
          {t("results.name.totalSum") +
            nameValues?.nameSum?.totalSumArray.join(" / ")}
        </Text>
        <Text color={colors.white} variant={textVariant}>
          {t("results.name.vowelSum") +
            nameValues?.nameSum?.vowelSumArray.join(" / ")}
        </Text>
        <Text color={colors.white} variant={textVariant}>
          {t("results.name.consonantSum") +
            nameValues?.nameSum?.consonantSumArray.join(" / ")}
        </Text>
        <Text color={colors.white} variant={textVariant}>
          {t("results.name.firstVowel") + nameValues?.firstVowel.toUpperCase()}
        </Text>
        <Text color={colors.white} variant={textVariant}>
          {t("results.name.firstLetter") +
            nameValues?.firstLetter.toUpperCase()}
        </Text>
      </TextContainer>
    );
  };

  const renderDateColumn = () => {
    const { variant: textVariant, titleVariant } = getTextVariant();
    return (
      <TextContainer>
        <Text color={colors.white} variant={titleVariant}>
          {formatDate(date)}
        </Text>
        <Text color={colors.white} variant={textVariant}>
          {t("results.date.totalSum") + dateValues?.reducedBirthday}
        </Text>
        <Text color={colors.white} variant={textVariant}>
          {t("results.date.dayType") +
            t(`results.dayType.${dateValues?.dayType}`)}
        </Text>
        <Text color={colors.white} variant={textVariant}>
          {t("results.date.firstChallenge") + dateValues?.firstChallenge}
        </Text>
        <Text color={colors.white} variant={textVariant}>
          {t("results.date.personalMonth") + dateValues?.personalMonth}
        </Text>
        <Text color={colors.white} variant={textVariant}>
          {t("results.date.personalYear") + dateValues?.personalYear}
        </Text>
      </TextContainer>
    );
  };

  const renderPowerfulNumber = () => {
    const { variant: textVariant } = getTextVariant();

    return (
      <TextContainer>
        <Text color={colors.white} variant={textVariant}>
          {t("results.powerfulNumber") + powerfulNumber}
        </Text>
      </TextContainer>
    );
  }

  const renderNameModal = () => (
    <div>
      {modalArray.map(key => (
        <Text variant="bodySmall" color={colors.white}>
          {t(`results.nameModal.${key}`)}
        </Text>
      ))}
    </div>
  );

  const renderDateModal = () => (
    <div>
      {modalArray.map(key => (
        <Text variant="bodySmall" color={colors.white}>
          {t(`results.dateModal.${key}`)}
        </Text>
      ))}
    </div>
  );

  return (
    <GradientPageBlock
      minHeight="70vh"
      paddingTop="72px"
      flexDirection="column"
      startColor={colors.black}
      endColor={colors.gunMetal}
    >
      <span>
        <Text variant="sectionSecondary" color={colors.white}>
          {t("results.name.nameDate")}
        </Text>
        <Modal>
          <div>
            <Text variant="sectionSecondary" color={colors.white}>
              {t("results.name.nameDate")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.nameSum")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.vowelSum")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.consonantSum")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.firstVowel")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.dateSum")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.dayType")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.firstChallenge")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.personalMonth")}
            </Text>
            <Text variant="bodyLarge" color={colors.white}>
              {t("results.information.personalYear")}
            </Text>
          </div>
        </Modal>
      </span>
      {renderBasicResults()}
    </GradientPageBlock>
  );
};

export default BaseSection;
