import React from "react";
import styled from "styled-components";
import { colors, measures } from "../../ui/theme";

const CustomBlock = styled.div`
  min-height: ${(props) => props.minHeight};
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    ${(props) => props.startColor || colors.black},
    ${(props) => props.endColor || colors.purpleNavy}
  );
  padding-left: 16px;
  padding-right: 16px;
  padding-top: ${(props) => props.paddingTop};

  ${measures.tablet} {
    padding-top: 0px;
    padding: 0 32px;
  }

  ${measures.desktop} {
    padding-top: 0px;
    padding: 0 96px;
  }
`;

const GradientPageBlock = ({
  children,
  background,
  minHeight,
  paddingTop,
  flexDirection,
  image,
  id,
  startColor,
  endColor,
}) => {
  return (
    <CustomBlock
      id={id}
      minHeight={minHeight}
      background={background}
      paddingTop={paddingTop}
      flexDirection={flexDirection}
      image={image}
      startColor={startColor}
      endColor={endColor}
    >
      {children}
    </CustomBlock>
  );
};

export default GradientPageBlock;
