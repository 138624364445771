import Modal from 'styled-react-modal';

import styled from 'styled-components';
import { colors, measures } from '../../ui/theme';

import { ReactComponent as questionMark } from "../../assets/questionMark.svg"
import { ReactComponent as closeIcon } from "../../assets/closeIcon.svg"

const StyledModal = Modal.styled`
  width: 90%;
  height: 70%;
  max-height: 70vh;
  overflow-y: auto;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: ${colors.gunMetal};
  border-radius: 4px;
`

const QuestionMark = styled(questionMark)`
  width: 28px;
  height: 28px;
  .lines {
    stroke: ${colors.white};
  }
  fill: ${colors.white};
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
`

const CloseIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const CloseIcon = styled(closeIcon)`
  width: 28px;
  height: 28px;
  fill: ${colors.white};
`
const ContentContainer = styled.div`
  ${measures.desktop} {
    padding: 16px 64px;
  }
`

export {StyledModal, QuestionMark, InnerContainer, CloseIcon, CloseIconContainer, ContentContainer}
