import styled from "styled-components";
import { colors } from "../../ui/theme";

const CustomText = styled.p`
  color: ${colors.white};
  font-family: MuseoSans;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: 'flex-end';
  align-items: 'flex-end';
`

const CustomLink = styled.a`
  text-decoration: none;
  color: ${colors.white};
`

export { CustomText, BottomContainer, CustomLink};
