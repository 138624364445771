import * as React from "react";

import { Section, CenteredTextContainer } from "./styles";
import { GridSection, GridItem, Text } from "../../../../ui";
import { colors } from "../../../../ui/theme";

import EmailForm from './components/emailForm/EmailForm';

const ContactSection = ({ description }) => {
  return (
    <Section>
      <GridSection>
        <GridItem m={2} t={8} d={12}>
          <CenteredTextContainer>
            <Text variant="section" color={colors.white}>
              {description}
            </Text>
            <EmailForm />
          </CenteredTextContainer>
        </GridItem>
      </GridSection>

    </Section>
  );
};

export default ContactSection;
