import styled from "styled-components";
import GridItem from "../../../../ui/GridItem";
import ImageWithFallback from '../../../../ui/ImageWithFallback';

import {measures} from '../../../../ui/theme';

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.bg};
`;

const Image = styled(ImageWithFallback)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 50vh;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  ${measures.tablet} {
    flex-direction: row;
  }
`

export { ButtonContainer, Section, CenteredGridItem, TextContainer, Image };
