import * as React from 'react';

const ImageWithFallback = ({
  source,
  fallback,
  type='image/webp',
  alt,
  ...delegated
}) => {

  return (<picture>
    <source srcSet={source} type={type}/>
    <img src={source} {...delegated}/>
  </picture>)
}
export default ImageWithFallback
