import React, { useRef } from "react";

import GradientPageBlock from '../../ui/GradientPageBlock';
import PageBlock from "../../ui/PageBlock";
import IntroSection from "./sections/IntroSection";
import AboutSection from "./sections/AboutSection";
import BookSection from "./sections/BookSection";
import WelcomeSection from "./sections/Welcome";
// import ContactSection from "./sections/Contact";
import Footer from "./sections/Footer";
import { CustomText, BottomContainer, CustomLink } from "./styles";
import { colors } from "../../ui/theme";

const ImgResults = "/background_main.webp";

const MainContainer = () => {
  const introSectionRef = useRef(null);

  const scrollToIntro = (ref) => {
    ref.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const onClick = () => {
    scrollToIntro(introSectionRef);
  };

  const mailTo = () => {
    window.location.href = `mailto:contatosabinanehmi@gmail.com`;
  }

  return (
    <div>
      <PageBlock
        minHeight="90vh"
        image={ImgResults}
        id="welcome"
        paddingTop="72px"
      >
        <WelcomeSection onClick={onClick} />

      </PageBlock>
      <PageBlock background={colors.black} >
        <BottomContainer>
          <CustomLink href="https://www.freepik.com/vectors/background">Background by visnezh</CustomLink>
        </BottomContainer>
      </PageBlock>
      <div ref={introSectionRef}>
        <GradientPageBlock minHeight="100vh" id="intro" >
          <IntroSection />
        </GradientPageBlock>
      </div>
      <PageBlock minHeight="70vh" background={colors.purpleNavy} id="about">
        <AboutSection />
      </PageBlock>
      <PageBlock minHeight="70vh" background={colors.purpleNavy} id="book">
        <BookSection />
      </PageBlock>
      {/* <PageBlock minHeight="70vh" background={colors.gunMetal} id="contact">
        <ContactSection description={t('contact.description')}onClick={mailTo} />
      </PageBlock> */}
      <PageBlock minHeight="40vh" background={colors.gunMetal} id="footer">
        <Footer mailTo={mailTo}/>
      </PageBlock>
      <PageBlock background={colors.gunMetal}>
        <CustomText>Copyright {new Date().getFullYear()} - Sabina Nehmi</CustomText>
      </PageBlock>
    </div>
  );
};

export default MainContainer;
