import * as React from 'react';

import { Anchor, CenteredGridItem, FooterList, InstagramLogo, ListItem, Section, SiteLogo, StaticListItem } from "./styles";
import GridSection from "../../../../ui/GridSection";

import ImgLogoFallback from '../../../../assets/logoWhite.png';
import ImgLogo from '../../../../assets/logoWhite.webp'


const Footer = ({mailTo}) => {
    return (
      <Section>
        <GridSection>
          <CenteredGridItem m={4} t={4} d={4}>
            <SiteLogo src={ImgLogo} fallback={ImgLogoFallback}/>
            <a href="https://www.instagram.com/sabinanehmi">
              <InstagramLogo />
            </a>
          </CenteredGridItem>
          <CenteredGridItem m={4} t={2} d={2}>
            <FooterList>
              <StaticListItem>
                TÓPICOS
              </StaticListItem>
              <ListItem>
                <Anchor smooth to="/#about">
                  Sobre
                </Anchor>
              </ListItem>
              <ListItem>
                <Anchor smooth to="/#book">
                  Livro
                </Anchor>
                </ListItem>
              <ListItem></ListItem>
            </FooterList>
          </CenteredGridItem>
          <CenteredGridItem m={4} t={2} d={2}>
            <FooterList>
              <StaticListItem>
                CONTATO
              </StaticListItem>
              <ListItem onClick={mailTo}>Email</ListItem>
            </FooterList>
          </CenteredGridItem>
        </GridSection>
      </Section>
    );
}

export default Footer;
