import React from "react";
import styled, { css } from "styled-components/macro";

import { typography, measures } from "../theme";

const Component = styled.p`
  color: ${(props) => props.color};
  text-align: ${(props) => props.textAlign};

  ${measures.tablet} {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 24px;
    ${({ px }) => css`
      padding-left: ${px}px;
      padding-right: ${px}px;
    `}
  }
`;

const Text = ({ color, children, variant, textAlign, px }) => {
  return (
    <Component
      as={typography[variant].component}
      css={typography[variant].style}
      color={color}
      textAlign={textAlign}
      px={px}
    >
      {children}
    </Component>
  );
};

export default Text;
