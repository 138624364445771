import React, { useState, useCallback, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import Navbar from "../../ui/Navbar";
import MainPage from "../../pages/Main";
import ResultsPage from "../../pages/Results";

const Main = (props) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const onScroll = useCallback(() => {
    setIsScrolled((window.pageYOffset || document.body.scrollTop) > 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () =>
      window.removeEventListener("scroll", onScroll, { passive: true });
  });

  return (
    <div>
      <Navbar isScrolled={isScrolled} size="841px" />
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exat path="/results" component={ResultsPage} />
      </Switch>
    </div>
  );
};

export default Main;
