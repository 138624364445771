import React from "react";
import { useTranslation } from "react-i18next";

import { Section, CenteredTextContainer } from "../styles";
import { PageBlock, GridSection, GridItem, Text, Button } from "../../../ui";
import { colors } from "../../../ui/theme";
const ImgResults = "/background_main.webp";

const IntroSection = ({ name, onClick }) => {
  const { t } = useTranslation();

  const splitName = name.split(" ");
  return (
    <PageBlock minHeight="90vh" image={ImgResults}>
      <Section>
        <GridSection>
          <GridItem m={2} t={8} d={12}>
            <CenteredTextContainer>
              <Text variant="display" color={colors.white}>
                {[splitName[0], t("results.title")].join(", ")}
              </Text>
              <Button width="200px" onClick={onClick}>
                {t("results.showMe")}
              </Button>
            </CenteredTextContainer>
          </GridItem>
        </GridSection>
      </Section>
    </PageBlock>
  );
};

export default IntroSection;
