export const letterMap = {
  a: 1,
  b: 2,
  c: 3,
  ç: 3,
  d: 4,
  e: 5,
  f: 6,
  g: 7,
  h: 8,
  i: 9,
  j: 1,
  k: 2,
  l: 3,
  m: 4,
  n: 5,
  o: 6,
  p: 7,
  q: 8,
  r: 9,
  s: 1,
  t: 2,
  u: 3,
  v: 4,
  w: 5,
  x: 6,
  y: 7,
  z: 8,
};

export const vowelArray = ["a", "e", "i", "o", "u"];

export const birthdayConstants = {
  science: "scientific",
  art: "artistic",
  business: "business",
};

export const birthdayMap = {
  1: birthdayConstants.science,
  2: birthdayConstants.business,
  3: birthdayConstants.art,
  4: birthdayConstants.business,
  5: birthdayConstants.science,
  6: birthdayConstants.art,
  7: birthdayConstants.science,
  8: birthdayConstants.business,
  9: birthdayConstants.art,
};
