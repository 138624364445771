import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 56px 20px;
  width: 70%;
  background: #f4f7f8;
  background: #f4f7f8;
  border-radius: 8px;
`;

const FormLabel = styled.label`
  display: block;
  width: 100%;
  text-align: left;
  padding: 0;
  font-size: 1.2em;
  font-family: Museo;
`;

const FormInput = styled.input`
  padding: 12px 10px;

  margin: 24px 0;
  border: none;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 8px 0;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`
export {
  Form,
  FormLabel,
  FormInput,
  ErrorMessage,
  LoadingContainer
};
