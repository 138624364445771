import React, { useContext } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Form, FormInput, FormLabel, ErrorMessage } from "./styles";
import Button from "../../../../ui/Button";
import AppContext from "../../../../AppContext";

const UserForm = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const { setUser } = useContext(AppContext);

  const validate = (values) => {
    const errors = {};
    if (!values.fullName) {
      errors.fullName = t("intro.error.required");
    } else if (values.fullName.length < 10) {
      errors.fullName = t("intro.error.stringLength");
    }

    if (!values.birthDate) {
      errors.birthDate = t("intro.error.required");
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      birthDate: "",
    },
    validate,
    onSubmit: (values) => {
      setUser({
        fullName: values.fullName,
        birthDate: values.birthDate,
      });
      history.push({
        pathname: "/results",
      });
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormLabel htmlFor="fullName">{t("intro.nameLabel")}</FormLabel>
      <FormInput
        id="fullName"
        name="fullName"
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.fullName}
      />
      {formik.touched.fullName && formik.errors.fullName ? (
        <ErrorMessage>{formik.errors.fullName}</ErrorMessage>
      ) : null}
      <FormLabel htmlFor="birthDate">{t("intro.birthDateLabel")}</FormLabel>
      <FormInput
        id="birthDate"
        name="birthDate"
        type="date"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.birthDate}
      />
      {formik.touched.birthDate && formik.errors.birthDate ? (
        <ErrorMessage>{formik.errors.birthDate}</ErrorMessage>
      ) : null}
      <Button type="submit">{t("intro.buttonText")}</Button>
    </Form>
  );
};

export default UserForm;
