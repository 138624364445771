import React from "react";

import styled from "styled-components";
import measures from "../theme/measures";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-column-gap: 16px;
  width: 100%;
  box-sizing: border-box;
  padding-top: ${props => props.paddingTop}px;

  ${measures.tablet} {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 24px;
  }

  ${measures.desktop} {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 72px;
  }
`;

export { StyledGrid };

const GridSection = (props) => {
  return (
    <StyledGrid
      m={props.m}
      t={props.t}
      d={props.d}
      columns={props.columns ? props.columns : 2}
      paddingTop={props.paddingTop}
    >
      {props.children}
    </StyledGrid>
  );
};

export default GridSection;
