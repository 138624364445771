import * as React from 'react';
import axios from 'axios';

const formUrl = 'https://script.google.com/macros/s/AKfycbyC7eWFLF-AcMKttKjqtQcrrNdLZ4MG-2zhiYnPHxs2puLBdScrpXdMkFS19zw-jAag/exec';

const usePostEmail = () => {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const postEmail = async (fullName, email, birthDate) => {
    setError(false);
    setSuccess(false);
    setLoading(true);
    const response = await axios.get(
      `${formUrl}?fullName=${encodeURIComponent(fullName)}&email=${encodeURIComponent(email)}&birthDate=${encodeURIComponent(birthDate)}&date=${encodeURIComponent(new Date().toLocaleString())}`
    );

    const { status } = response;
    if(status >= 400 && response <= 599){
      setError(true);
    } else {
      setSuccess(true);
    }
    setLoading(false);
  }

  return {loading, success, error, postEmail};
}
export default usePostEmail;
