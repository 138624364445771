import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from 'react-i18next';

import AppContext from "../../AppContext";

import { calculateResults } from "../../helpers/calculator";

import BaseSection from "./sections/BaseSection";
import PinnacleSection from "./sections/PinnacleSection.js";
import PyramidSection from "./sections/PyramidSection";
import IntroSection from "./sections/IntroSection";
import ContactSection from "../Main/sections/Contact";

import { colors } from "../../ui/theme";
import PageBlock from "../../ui/PageBlock";

import { CustomLink, BottomContainer } from './styles';

const Results = () => {
  const [nameValues, setNameValues] = useState(undefined);
  const [dateValues, setDateValues] = useState(undefined);
  const [powerfulNumber, setPowerfulNumber] = useState(0);

  const { user } = useContext(AppContext);

  const baseSectionRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const {powerfulNumber, nameResults, dateResults} = calculateResults(user.fullName, user.birthDate);

    setNameValues(nameResults);
    setDateValues(dateResults);
    setPowerfulNumber(powerfulNumber);
  }, [user, user.fullName, user.birthDate]);

  const scrollToResults = (ref) => {
    ref.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const onClick = () => {
    scrollToResults(baseSectionRef);
  };

  return (
    <div>
      <IntroSection name={user.fullName} onClick={onClick} />
      <PageBlock background={colors.black} >
        <BottomContainer>
          <CustomLink href="https://www.freepik.com/vectors/background">Background by visnezh</CustomLink>
        </BottomContainer>
      </PageBlock>
      <div ref={baseSectionRef}>
        <BaseSection
          date={user.birthDate}
          dateValues={dateValues}
          name={user.fullName}
          nameValues={nameValues}
          powerfulNumber={powerfulNumber}
        />
      </div>
      <PyramidSection
        inverseTriangleArray={nameValues?.inverseTriangleNumber}
      />
      <PinnacleSection
        pinnacles={dateValues?.pinnacles}
        challenges={dateValues?.challenges}
      />
      <PageBlock minHeight="70vh" background={colors.gunMetal} id="contact">
        <ContactSection description={t('contact.resultsDescription')} />
      </PageBlock>
    </div>
  );
};

export default Results;
