import styled from 'styled-components';
import { HashLink as Link } from "react-router-hash-link";
import GridItem from "../../../../ui/GridItem";
import { ReactComponent as instagram} from "../../../../assets/instagram.svg"
import { colors } from "../../../../ui/theme";

import ImageWithFallback from '../../../../ui/ImageWithFallback';

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${(props) => props.bg};
`;

const SiteLogo = styled(ImageWithFallback)`
  height: 140px;
`;

const InstagramLogo = styled(instagram)``;

const FooterList = styled.ul`
  display: block;
  list-style: none;
  padding: 10px 0;
  margin: 0;
  align-items: center;
  transition: all 0.3s ease;
  @media (max-width: ${(props) => props.size}) {
    display: block;
  }
`;

const ListItem = styled.li`
  color: ${colors.white};
  margin: 0 0 10px 0;
  transition: 0.3s ease;
  text-decoration: none;
  border-bottom: transparent 1px solid;
  &:hover {
    border-bottom: 1px solid;
    border-bottom-color: ${(props) =>
      props.isScrolled ? colors.black : colors.white};
  }
  @media (max-width: ${(props) => props.size}) {
    display: block;
    margin: 20px 0;
  }
`;

const StaticListItem = styled.li`
  cursor: default;
  color: ${colors.white};
  margin: 0 0 10px 0;
  transition: 0.3s ease;
  text-decoration: none;
  border-bottom: transparent 1px solid;
  @media (max-width: ${(props) => props.size}) {
    display: block;
    margin: 20px 0;
  }
`;

const Anchor = styled(Link)`
  text-decoration: none;
  color: ${(props) => (props.isScrolled ? colors.black : colors.white)};
`;

export { Anchor, CenteredGridItem, FooterList, InstagramLogo, ListItem, Section, SiteLogo, StaticListItem };
