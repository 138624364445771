import React from "react";
import { useTranslation } from "react-i18next";

import { Section, CenteredGridItem, TextContainer } from "./styles";
import { colors } from "../../../../ui/theme";
import UserForm from "./UserForm";
import GridSection from "../../../../ui/GridSection";
import Text from "../../../../ui/Text";

const IntroSection = (props) => {
  const { t } = useTranslation();
  return (
    <Section>
      <GridSection>
        <CenteredGridItem m={2} t={8} d={6}>
          <TextContainer>
            <Text color={colors.white} variant="section">
              {t("intro.welcome")}
            </Text>
            <Text
              color={colors.white}
              variant="bodyLarge"
              textAlign="justify"
              px={50}
            >
              {t("intro.description.part1")}
            </Text>
            <Text
              color={colors.white}
              variant="bodyLarge"
              textAlign="justify"
              px={50}
            >
              {t("intro.description.part2")}
            </Text>
            <Text
              color={colors.white}
              variant="bodyLarge"
              textAlign="justify"
              px={50}
            >
              {t("intro.description.part3")}
            </Text>
          </TextContainer>
        </CenteredGridItem>
        <CenteredGridItem m={2} t={8} d={6}>
          <UserForm />
        </CenteredGridItem>
      </GridSection>
    </Section>
  );
};

export default IntroSection;
