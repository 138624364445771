import * as React from 'react'
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Form, FormInput, FormLabel, ErrorMessage, LoadingContainer } from "./styles";
import Button from "../../../../../../ui/Button";
import AppContext from "../../../../../../AppContext";

import usePostEmail from '../../hooks/usePostEmail';
import { Text } from '../../../../../../ui';

import { BallTriangle } from '@agney/react-loading';

const EmailForm = () => {
  const { t } = useTranslation();
  const {user} = React.useContext(AppContext);
  const {loading, success, error, postEmail} = usePostEmail()

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Por favor, digite seu email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Por favor, confira seu email';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: async (values) => {
      postEmail(user.fullName, values.email, user.birthDate);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormLabel htmlFor="fullName">{t("results.emailForm.title")}</FormLabel>
      <FormInput
        id="email"
        name="email"
        type="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.fullName}
      />
      {formik.touched.email && formik.errors.email ? (
        <ErrorMessage>{formik.errors.email}</ErrorMessage>
      ) : null}
      <Button type="submit">{t("results.emailForm.buttonText")}</Button>
      {loading && <LoadingContainer><BallTriangle width="36"/></LoadingContainer>}
      {success && <Text variant="bodySmall">{t("results.emailForm.successMessage")}</Text>}
      {error && <Text variant="bodySmall">(t("results.emailForm.errorMessage"))</Text>}
    </Form>
  );
};

export default EmailForm;
