import React from "react";
import styled, { css } from "styled-components";
import { colors, measures } from "../theme";

const LinkButton = styled.button`
  border: none;
  background-color: ${colors.ronchi};
  color: ${colors.white};
  border-radius: 15px;
  transition-duration: 0.3s;
  font-family: Museo;
  font-size: 17px;
  padding: 16px 32px;

  &:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  ${measures.desktop} {
    font-size: 20px;
    padding: 20px 40px;
  }

  ${({ width }) => css`
    width: ${width};
  `}

  margin: ${(props) => props.margin}
`;

const Button = ({ width, onClick, children, margin }) => {
  return (
    <LinkButton width={width} onClick={onClick} margin={margin}>
      {children}
    </LinkButton>
  );
};

export default Button;
