import { giveNameCalculations } from "./nameCalculator";
import calculateDateObject from "./dateCalculator";

const getReducedNumber = (value) => {
  let result = value;
  if (result >= 10) {
    result = ((result / 10) >> 0) + (result % 10 >> 0);
  }
  return result;
};

export const calculateResults = (fullName, birthDate) =>{
  const nameResults = giveNameCalculations(fullName);
  const dateResults = calculateDateObject(birthDate);

  const {totalSumArray} = nameResults.nameSum


  const powerfulNumber = getReducedNumber(totalSumArray[totalSumArray.length - 1] + dateResults.reducedBirthday)

  return { powerfulNumber, nameResults, dateResults }
};
