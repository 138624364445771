import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonContainer, Section, CenteredGridItem, TextContainer, Image } from "./styles";
import Text from "../../../../ui/Text";
import ImgBook from "../../../../assets/bookCover.webp";
import ImgBookFallback from "../../../../assets/bookCover.png";
import GridSection from "../../../../ui/GridSection";
import Button from "../../../../ui/Button";
import { colors } from "../../../../ui/theme";

const FIRST_BOOK_LINK = "https://www.amazon.com.br/Ciencia-Dos-N%C3%BAmeros-Numerologia/dp/6500133447/ref=sr_1_2?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=sabina+nehmi&qid=1616243003&sr=8-2"
const SECOND_BOOK_LINK = "https://www.magazineluiza.com.br/a-ciencia-dos-numeros-e-a-numerologia-sabina-nehmi/p/jg6h9e8108/li/liao/"

const BookSection = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <GridSection>
        <CenteredGridItem m={2} t={8} d={6}>
          <TextContainer>
            <Text color={colors.white} variant="section">{t("book.title")}</Text>
            <Text color={colors.white} variant="bodyLarge" textAlign="justify" px={50}>
              {t("book.description")}
            </Text>
            <Text color={colors.white} variant="bodyLarge" textAlign="center" px={50}>
              {t("book.buyText")}
            </Text>
          </TextContainer>
          <ButtonContainer>
            <a href={FIRST_BOOK_LINK} target="_blank" rel="noopener noreferrer">
              <Button margin={'16px 16px'}>
                {t("book.firstLink")}
              </Button>
            </a>
            <a href={SECOND_BOOK_LINK} target="_blank" rel="noopener noreferrer">
              <Button>
                {t("book.secondLink")}
              </Button>
            </a>
          </ButtonContainer>
        </CenteredGridItem>
        <CenteredGridItem m={2} t={8} d={6}>
          <Image src={ImgBook} fallback={ImgBookFallback} />
        </CenteredGridItem>
      </GridSection>
    </Section>
  );
};

export default BookSection;
