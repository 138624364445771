import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { ModalProvider, BaseModalBackground } from 'styled-react-modal'

import { AppProvider } from "./AppContext";
import "./App.css";
import Main from "./containers/main";
import ScrollToTop from "./helpers/scrollToTop";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Museo;
    background-color: #000000
  }
`;

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition : all 0.3s ease-in-out;
`;

function App() {
  const [user, setUser] = useState({
    fullName: undefined,
    birthDate: undefined,
  });

  return (
    <AppProvider
      value={{
        user: user,
        setUser: setUser,
      }}
    >
    <ModalProvider backgroundComponent={FadingBackground}>
      <BrowserRouter>
        <ScrollToTop>
          <GlobalStyle />
          <div className="App">
            <Main />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    </ModalProvider>
    </AppProvider>
  );
}

export default App;
