import * as React from 'react';

import {StyledModal, QuestionMark, CloseIcon, CloseIconContainer, InnerContainer, ContentContainer} from './styles';

function FancyModalButton({children}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [opacity, setOpacity] = React.useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);

  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0);
      setTimeout(resolve, 300);
    })
  }
    return (
      <div>
        <QuestionMark onClick={toggleModal}>Click me</QuestionMark>
        <StyledModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}>
          <InnerContainer>
            <CloseIconContainer>
              <CloseIcon onClick={toggleModal}/>
            </CloseIconContainer>
            <ContentContainer>
              {children}
            </ContentContainer>
          </InnerContainer>
        </StyledModal>
      </div>
    )
}

export default FancyModalButton;


